import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Calendar, Tag } from "lucide-react";
import { Link } from "react-router-dom";
import frontMatter from 'front-matter';
import { Helmet } from 'react-helmet-async';

interface PostMetadata {
  title: string;
  excerpt?: string;
  date: string;
  category: string;
  tags?: string[];  // Optional for backward compatibility
}

interface Post {
  id: string;
  metadata: PostMetadata;
}

const Articles = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const postFiles = import.meta.glob('../articles/*.md', { as: 'raw', eager: true });
        
        const postsData = Object.entries(postFiles).map(([path, content]: [string, string]) => {
          try {
            const { attributes } = frontMatter<PostMetadata>(content);
            const id = path.split('/').pop()?.replace(/\.md$/, '') || '';
            
            // Handle old posts that might have tags but no category
            const category = attributes.category || (attributes.tags?.[0] || 'Uncategorized');
            
            return {
              id,
              metadata: {
                title: attributes.title,
                excerpt: attributes.excerpt,
                date: attributes.date,
                category: category
              }
            };
          } catch (error) {
            console.error('Error processing file:', path, error);
            return null;
          }
        });

        const validPosts = postsData.filter(Boolean);
        const sortedPosts = validPosts.sort((a, b) => 
          new Date(b.metadata.date).getTime() - new Date(a.metadata.date).getTime()
        );

        setPosts(sortedPosts);
      } catch (error) {
        console.error('Error loading posts:', error);
      }
    };

    loadPosts();
  }, []);

  return (
    <>
      <Helmet>
        <title>Security Research Articles | greycr0w</title>
        <meta name="description" content="Security research articles and vulnerability analysis by greycr0w. Covering web security, penetration testing, and vulnerability research." />
        <meta name="keywords" content="security research, web security, penetration testing, vulnerability analysis, cybersecurity" />
        <meta property="og:title" content="Security Research Articles | greycr0w" />
        <meta property="og:description" content="Security research articles and vulnerability analysis by greycr0w. Covering web security, penetration testing, and vulnerability research." />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="min-h-screen bg-background">
        <Header />
        <main className="container mx-auto px-4 pt-24 pb-12">
          <h1 className="text-4xl font-bold mb-8 text-foreground">articles</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts.map((post) => (
              <Link 
                key={post.id} 
                to={`/articles/${post.id}`}
                className="group"
              >
                <div className="terminal-window h-full bg-secondary border border-accent/10 rounded-lg p-6 
                  hover:border-accent/30 transition-all duration-200 hover:shadow-lg hover:-translate-y-1"
                >
                  <div className="terminal-content">
                    <div className="text-sm text-accent/50 mb-2 font-mono">$ cat article.md</div>
                    <h2 className="text-xl font-medium text-foreground group-hover:text-primary transition-colors mb-3">
                      {post.metadata.title}
                    </h2>
                    {post.metadata.excerpt && (
                      <p className="text-sm text-muted line-clamp-3 mb-4">
                        {post.metadata.excerpt}
                      </p>
                    )}
                    
                    <div className="flex items-center justify-between text-sm text-accent/70">
                      <div className="flex items-center">
                        <Calendar className="w-4 h-4 mr-2" />
                        {post.metadata.date}
                      </div>
                      <span className="px-2 py-0.5 text-xs rounded-full bg-accent/10 text-primary 
                        border border-accent/20 group-hover:bg-accent/20 transition-colors"
                      >
                        {post.metadata.category}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </main>
      </div>
    </>
  );
};

export default Articles;
