import React, { useState, useRef, useEffect } from 'react';

interface Command {
  command: string;
  output: string;
}

interface TerminalProps {
  commands: Record<string, string | ((...args: string[]) => string | Promise<string>)>;
  prompt?: string;
  className?: string;
  initialCommand?: string;
}

const ModularTerminal = ({ commands, prompt = "$", className = "", initialCommand }: TerminalProps) => {
  const [commandHistory, setCommandHistory] = useState<Command[]>([]);
  const [currentCommand, setCurrentCommand] = useState(initialCommand || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const outputRef = useRef<HTMLDivElement>(null);

  const handleCommand = async (cmd: string) => {
    const trimmedCmd = cmd.trim();
    const [command, ...args] = trimmedCmd.split(' ');
    
    let output = '';
    
    if (trimmedCmd === '') {
      output = '';
    } else if (command === 'clear') {
      setCommandHistory([]);
      setCurrentCommand('');
      return;
    } else if (command in commands) {
      const handler = commands[command];
      if (typeof handler === 'function') {
        output = await handler(...args);
      } else {
        output = handler;
      }
    } else {
      output = `Command not found: ${command}`;
    }

    setCommandHistory(prev => [...prev, { command: trimmedCmd, output }]);
    setCurrentCommand('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleCommand(currentCommand);
    }
  };

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [commandHistory]);

  return (
    <div className={`terminal-window bg-secondary border border-accent/10 rounded-lg overflow-hidden h-[400px] ${className}`}>
      <div className="terminal-header flex items-center px-4 py-2 border-b border-[#333333]">
        <div className="flex space-x-2">
          <div className="w-3 h-3 rounded-full bg-red-500"></div>
          <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
          <div className="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
      </div>
      
      <div ref={outputRef} className="terminal-content h-[calc(100%-2.5rem)] overflow-y-auto">
        {commandHistory.map((cmd, index) => (
          <div key={index} className="mb-4">
            <div className="flex items-center text-[#888888] px-4">
              <span className="mr-2">{prompt}</span>
              <span>{cmd.command}</span>
            </div>
            {cmd.output && (
              <div className="mt-2 text-[#CCCCCC] whitespace-pre-wrap px-4">
                {cmd.output}
              </div>
            )}
          </div>
        ))}
        <div className="flex items-center text-[#888888] px-4">
          <span className="mr-2">{prompt}</span>
          <input
            ref={inputRef}
            type="text"
            value={currentCommand}
            onChange={(e) => setCurrentCommand(e.target.value)}
            onKeyDown={handleKeyDown}
            className="command-input flex-1 bg-transparent outline-none text-[#CCCCCC]"
            autoFocus
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            data-form-type="other"
          />
        </div>
      </div>
    </div>
  );
};

export default ModularTerminal; 