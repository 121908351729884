import Terminal from "../components/Terminal";
import { Helmet } from 'react-helmet-async';

const Index = () => {
  return (
    <>
      <Helmet>
        <title>greycr0w | Security Research & Vulnerability Analysis</title>
        <meta name="description" content="Security researcher specializing in web security, vulnerability research, and penetration testing. Explore security research articles and findings." />
        <meta name="keywords" content="security research, web security, penetration testing, vulnerability analysis, cybersecurity" />
        <meta property="og:title" content="greycr0w | Security Research & Vulnerability Analysis" />
        <meta property="og:description" content="Security researcher specializing in web security, vulnerability research, and penetration testing. Explore security research articles and findings." />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="min-h-screen bg-background p-8 flex items-center justify-center">
        <div className="w-full max-w-4xl">
          <Terminal />
        </div>
      </div>
    </>
  );
};

export default Index;
