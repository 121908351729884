import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HUGGINGFACE_TOKEN } from '../config/api';

interface Command {
  command: string;
  output: string;
}

interface NetworkInformation {
  effectiveType: string;
}

interface LLMResponse {
  generated_text: string;
}

const Terminal = () => {
  const [commands, setCommands] = useState<Command[]>([]);
  const [currentCommand, setCurrentCommand] = useState('');
  const [commandHistory, setCommandHistory] = useState<string[]>(() => {
    const savedHistory = localStorage.getItem('commandHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });
  const [historyIndex, setHistoryIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const outputRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [easterEggCache, setEasterEggCache] = useState<string[]>([]);

  const sanitizeOutput = (input: string): string => {
    const div = document.createElement('div');
    div.textContent = input;
    return div.textContent || '';
  };

  const detectXSSAttempt = (input: string): string | null => {
    const xssPatterns = [
      { pattern: /<script/i, response: "Nice try! But I eat script tags for breakfast! 🍳" },
      { pattern: /<img/i, response: "Trying to sneak in an img tag? How about this picture instead: 🖼️" },
      { pattern: /alert\s*\(/i, response: "Alert? The only thing being alerted is my XSS detector! 🚨" },
      { pattern: /on\w+\s*=/i, response: "Event handlers? More like event hand-losers! 🎭" },
      { pattern: /javascript:/i, response: "javascript: protocol? How vintage! Here's a modern solution: 🚫" },
      { pattern: /<svg/i, response: "SVG injection? Here's an ASCII art instead: ¯\\_(ツ)_/¯" },
      { pattern: /document\.cookie/i, response: "Looking for cookies? Here's one: 🍪" },
      { pattern: /eval\s*\(/i, response: "eval()? More like evil()! 😈" },
      { pattern: /window\./i, response: "Trying to access window? Here's a better view: 🪟" },
      { pattern: /fetch\s*\(/i, response: "Fetch? Good doggy! 🐕" }
    ];

    for (const { pattern, response } of xssPatterns) {
      if (pattern.test(input)) {
        return response;
      }
    }
    return null;
  };

  const getSystemInfo = (): string => {
    const userAgent = sanitizeOutput(navigator.userAgent);
    const connection = (navigator as any).connection as NetworkInformation | undefined;
    const connectionInfo = connection ? 
      `\nConnection: ${sanitizeOutput(connection.effectiveType)}` : '';
    
    return `guest@terminal
User Agent: ${userAgent}${connectionInfo}
Platform: ${sanitizeOutput(navigator.platform)}`;
  };

  const generateEasterEgg = useCallback(async (): Promise<string> => {
    try {
      const prompt = `Generate a playful, hacker-themed message that would appear when someone discovers hidden files in a terminal, similar to:
      "# Snooping around, are we? I like your style."
      "# Getting warmer... but these aren't the files you're looking for."
      Make it witty and security-themed, but keep it under 60 characters.`;

      const response = await fetch(
        'https://api-inference.huggingface.co/models/mistralai/Mistral-7B-Instruct-v0.1',
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${HUGGINGFACE_TOKEN}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ inputs: prompt }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to generate message');
      }

      const data: LLMResponse = await response.json();
      const message = data.generated_text.trim();
      
      // Cache the new message
      setEasterEggCache(prev => [...prev, message]);
      
      return message;
    } catch (error) {
      console.error('Error generating easter egg:', error);
      // Fallback to static messages if API fails
      return [
        '# Snooping around, are we? I like your style.',
        '# Getting warmer... but these aren\'t the files you\'re looking for.',
        '# A curious mind is a hacker\'s best tool.',
        '# Digging deeper? That\'s what I would do.',
        '# You found the hidden files! But what\'s inside them?',
        '# rm -rf /* # Just kidding, don\'t try this at home',
        '# Interesting technique... noted for future reference.',
        '# cat .secrets > /dev/null # Nothing to see here'
      ][Math.floor(Math.random() * 8)];
    }
  }, []);

  const formatListing = async (items: string[], showHidden = false) => {
    if (showHidden) {
      const hiddenFiles = [
        '.',
        '..',
        '.secrets',
        '.hidden_research',
        '.definitely_not_suspicious',
        '.nice_try_hacker.txt',
        '.you_are_very_curious.md',
        '.keep_digging.sh'
      ];

      // Get a message from cache or generate new one
      let message;
      if (easterEggCache.length > 0) {
        message = easterEggCache[Math.floor(Math.random() * easterEggCache.length)];
      } else {
        message = await generateEasterEgg();
      }

      return `${message}\n${[...hiddenFiles, ...items].join('\n')}`;
    }
    return items.join('\n');
  };

  const availableCommands = {
    help: 'Available commands:\n  help - Show this help message\n  ls - List available pages\n  cd <page/article> - Navigate to a page or view an article\n  clear - Clear terminal\n  pwd - Show current path\n  whoami - Display user info\n  date - Show current date and time\n  echo <message> - Display a message\n  version - Show system version',
    
    ls: async (arg?: string) => {
      const directories = ['articles', 'about'];
      const currentPath = window.location.pathname.slice(1);
      const pathParts = currentPath.split('/');
      
      // Parse arguments
      const showHidden = arg === '-a';
      const targetDir = showHidden ? undefined : arg?.replace(/\/$/, '') || pathParts[0];
      
      if (targetDir === 'articles') {
        try {
          // Get all markdown files in the articles directory
          const availablePosts = await import.meta.glob('../articles/*.md', { as: 'raw', eager: true });
          
          const posts = Object.keys(availablePosts)
            .map(path => {
              const filename = path.split('/').pop() || '';
              return filename.replace('.md', '');
            })
            .filter(Boolean);
          
          return posts.length ? await formatListing(posts, showHidden) : 'No articles found';
        } catch (error) {
          console.error('Error listing articles:', error);
          return `Error: Unable to list articles - ${error instanceof Error ? error.message : 'Unknown error'}`;
        }
      }
      
      // If we're in root directory or articles directory
      if (!targetDir || directories.includes(targetDir)) {
        return await formatListing(directories, showHidden);
      }
      
      return `ls: cannot access '${targetDir}': No such file or directory`;
    },
    
    cd: async (path: string) => {
      // Check for XSS in path
      const xssResponse = detectXSSAttempt(path);
      if (xssResponse) return xssResponse;

      if (path === '~' || path === '/') {
        navigate('/');
        return 'Navigating to home';
      }
      
      // Remove leading slash if present
      const normalizedPath = path.startsWith('/') ? path.slice(1) : path;
      
      if (normalizedPath === '..') {
        const currentPath = window.location.pathname;
        const parentPath = currentPath.split('/').slice(0, -1).join('/');
        navigate(parentPath || '/');
        return `Navigating to ${parentPath || '/'}`;
      }

      // List of valid directories
      const validDirectories = ['articles', 'about'];
      
      // Check if path is a valid directory
      if (validDirectories.includes(normalizedPath)) {
        navigate('/' + normalizedPath);
        return `Navigating to /${normalizedPath}`;
      }
      
      // Check if it's an article
      if (normalizedPath.includes('/')) {
        // Handle paths like "articles/article-name"
        const [dir, articleName] = normalizedPath.split('/');
        
        if (dir === 'articles' && articleName) {
          try {
            // Check if article exists
            const availablePosts = await import.meta.glob('../articles/*.md', { as: 'raw', eager: true });
            const articlePath = Object.keys(availablePosts).find(path => 
              path.endsWith(`${articleName}.md`)
            );
            
            if (articlePath) {
              navigate(`/articles/${articleName}`);
              return `Opening article: ${articleName}`;
            } else {
              return `Article not found: ${articleName}`;
            }
          } catch (error) {
            return `Error accessing article: ${error instanceof Error ? error.message : 'Unknown error'}`;
          }
        }
        
        return `cd: no such directory: ${normalizedPath}`;
      } else {
        // Check if it's a direct article name (without articles/ prefix)
        try {
          const availablePosts = await import.meta.glob('../articles/*.md', { as: 'raw', eager: true });
          const articlePath = Object.keys(availablePosts).find(path => 
            path.endsWith(`${normalizedPath}.md`)
          );
          
          if (articlePath) {
            navigate(`/articles/${normalizedPath}`);
            return `Opening article: ${normalizedPath}`;
          }
        } catch (error) {
          console.error('Error checking article:', error);
        }
      }
      
      return `cd: no such directory or article: ${normalizedPath}\nAvailable directories: ${validDirectories.join(', ')}`;
    },
    
    clear: () => {
      setCommands([]);
      return '';
    },
    pwd: () => window.location.pathname || '/',
    whoami: () => getSystemInfo(),
    date: () => new Date().toLocaleString(),
    echo: (...args: string[]) => {
      const input = args.join(' ');
      const xssResponse = detectXSSAttempt(input);
      return xssResponse || sanitizeOutput(input);
    },
    version: () => 'greycr0w@shell v1.0.0'
  };

  const getCommandSuggestions = (input: string): string[] => {
    const commandNames = Object.keys(availableCommands);
    return commandNames.filter(cmd => cmd.startsWith(input.toLowerCase()));
  };

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
    inputRef.current?.focus();
  }, [commands]);

  useEffect(() => {
    localStorage.setItem('commandHistory', JSON.stringify(commandHistory));
  }, [commandHistory]);

  const handleCommand = async (cmd: string) => {
    const trimmedCmd = cmd.trim();
    if (trimmedCmd) {
      setCommandHistory(prev => [...prev, trimmedCmd]);
      setHistoryIndex(-1);
    }
    
    const [command, ...args] = trimmedCmd.split(' ');
    
    let output = '';
    
    if (trimmedCmd === '') {
      output = '';
    } else if (command in availableCommands) {
      if (typeof availableCommands[command as keyof typeof availableCommands] === 'function') {
        output = await (availableCommands[command as keyof typeof availableCommands] as Function)(...args);
      } else {
        output = availableCommands[command as keyof typeof availableCommands] as string;
      }
    } else {
      output = `Command not found: ${command}. Type 'help' for available commands.`;
    }

    if (command !== 'clear') {
      setCommands(prev => [...prev, { command: trimmedCmd, output }]);
    }
    setCurrentCommand('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleCommand(currentCommand);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (commandHistory.length > 0) {
        const newIndex = historyIndex + 1;
        if (newIndex < commandHistory.length) {
          setHistoryIndex(newIndex);
          setCurrentCommand(commandHistory[commandHistory.length - 1 - newIndex]);
        }
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setCurrentCommand(commandHistory[commandHistory.length - 1 - newIndex]);
      } else if (historyIndex === 0) {
        setHistoryIndex(-1);
        setCurrentCommand('');
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      const suggestions = getCommandSuggestions(currentCommand);
      if (suggestions.length === 1) {
        setCurrentCommand(suggestions[0]);
      }
    }
  };

  const handleTerminalClick = () => {
    inputRef.current?.focus();
  };

  const handleClose = () => {
    setIsClosed(true);
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleReopen = () => {
    setIsClosed(false);
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleMinimize = () => {
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setIsMinimized(false);
  };

  const handleRestore = () => {
    setIsMinimized(false);
  };

  const terminalClasses = `terminal-window overflow-hidden transition-all duration-300 ${
    isClosed ? 'hidden' : isMinimized ? 'h-0 opacity-0' : isMaximized ? 'fixed inset-0 z-50' : 'w-full h-[60vh] max-h-[600px]'
  }`;

  return (
    <>
      <div className={terminalClasses} onClick={handleTerminalClick}>
        <div className="terminal-header flex items-center px-4 py-2 border-b border-[#333333]">
          <div className="flex space-x-2">
            <button 
              onClick={handleClose}
              className="terminal-circle w-3 h-3 rounded-full bg-red-500 flex items-center justify-center hover:bg-red-600 group"
            >
              <svg className="w-2 h-2 text-red-900" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <button 
              onClick={handleMinimize}
              className="terminal-circle w-3 h-3 rounded-full bg-yellow-500 flex items-center justify-center hover:bg-yellow-600 group"
            >
              <svg className="w-2 h-2 text-yellow-900" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
              </svg>
            </button>
            <button 
              onClick={handleMaximize}
              className="terminal-circle w-3 h-3 rounded-full bg-green-500 flex items-center justify-center hover:bg-green-600 group"
            >
              <svg className="w-2 h-2 text-green-900" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7" />
              </svg>
            </button>
          </div>
          <span className="ml-2 text-sm text-[#888888]">greycr0w@shell</span>
        </div>
        <div ref={outputRef} className="terminal-content h-[calc(100%-2.5rem)] overflow-y-auto">
          {commands.map((cmd, index) => (
            <div key={index} className="mb-4 px-4">
              <div className="flex items-center text-[#888888]">
                <span className="mr-2">$</span>
                <span>{cmd.command}</span>
              </div>
              {cmd.output && (
                <div className="mt-2 text-[#CCCCCC] whitespace-pre-wrap">
                  {cmd.output}
                </div>
              )}
            </div>
          ))}
          <div className="flex items-center text-[#888888] px-4">
            <span className="mr-2">$</span>
            <input
              ref={inputRef}
              type="text"
              value={currentCommand}
              onChange={(e) => setCurrentCommand(e.target.value)}
              onKeyDown={handleKeyDown}
              className="command-input flex-1 bg-transparent outline-none text-[#CCCCCC]"
              autoFocus
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              data-form-type="other"
            />
          </div>
        </div>
      </div>

      {/* Bottom dock/menu item - show when minimized OR closed */}
      {(isMinimized || isClosed) && (
        <button
          onClick={isClosed ? handleReopen : handleRestore}
          className="fixed bottom-4 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-[#222222] rounded-lg 
            shadow-lg flex items-center space-x-2 hover:bg-[#333333] transition-colors duration-200"
        >
          <svg className="w-4 h-4 text-[#888888]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
              d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
          </svg>
          <span className="text-[#888888]">Terminal</span>
        </button>
      )}
    </>
  );
};

export default Terminal;
