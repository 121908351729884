import { User, Shield, Github, Twitter, Linkedin, Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-sm border-b border-accent/10">
      <div className="container flex items-center justify-between h-16">
        <Link to="/" className="flex items-center space-x-2 shrink-0">
          <img 
            src="/logo.png" 
            alt="greycr0w logo" 
            className="w-8 h-8 md:w-12 md:h-12 object-contain"
          />
          <span className="font-medium text-foreground text-sm md:text-base">greycr0w</span>
        </Link>
        
        {/* Mobile Menu Button */}
        <button 
          className="md:hidden p-1.5 text-accent hover:text-primary flex items-center"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <X className="w-5 h-5 mt-0.5" /> : <Menu className="w-5 h-5 mt-0.5" />}
        </button>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6">
          <nav>
            <ul className="flex space-x-6">
              <li>
                <Link 
                  to="/articles" 
                  className="text-accent hover:text-primary transition-colors"
                >
                  articles
                </Link>
              </li>
              <li>
                <Link 
                  to="/about" 
                  className="text-accent hover:text-primary transition-colors"
                >
                  about
                </Link>
              </li>
            </ul>
          </nav>

          <div className="flex items-center space-x-4 border-l border-accent/10 pl-6">
            <a
              href="https://github.com/greycr0w"
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent hover:text-primary transition-colors"
              aria-label="GitHub"
            >
              <Github className="w-5 h-5" />
            </a>
            <a
              href="https://twitter.com/10000tests"
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent hover:text-primary transition-colors"
              aria-label="Twitter"
            >
              <Twitter className="w-5 h-5" />
            </a>
            <a
              href="https://linkedin.com/in/badrishvili"
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent hover:text-primary transition-colors"
              aria-label="LinkedIn"
            >
              <Linkedin className="w-5 h-5" />
            </a>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-background border-b border-accent/10 md:hidden">
            <nav className="container py-4">
              <ul className="space-y-4">
                <li>
                  <Link 
                    to="/articles" 
                    className="text-accent hover:text-primary transition-colors block"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    articles
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/about" 
                    className="text-accent hover:text-primary transition-colors block"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    about
                  </Link>
                </li>
              </ul>
              <div className="flex space-x-4 mt-4 pt-4 border-t border-accent/10">
                <a
                  href="https://github.com/greycr0w"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accent hover:text-primary transition-colors"
                  aria-label="GitHub"
                >
                  <Github className="w-5 h-5" />
                </a>
                <a
                  href="https://twitter.com/10000tests"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accent hover:text-primary transition-colors"
                  aria-label="Twitter"
                >
                  <Twitter className="w-5 h-5" />
                </a>
                <a
                  href="https://linkedin.com/in/badrishvili"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accent hover:text-primary transition-colors"
                  aria-label="LinkedIn"
                >
                  <Linkedin className="w-5 h-5" />
                </a>
              </div>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
