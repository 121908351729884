import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { Calendar, Tag, User, Copy, Check } from "lucide-react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import frontMatter from 'front-matter';
import { Helmet } from 'react-helmet-async';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface PostMetadata {
  title: string;
  date: string;
  lastModified?: string;
  author: string;
  category: string;
  tags?: string[];
  excerpt: string;
  canonicalUrl?: string;
}

interface Post {
  content: string;
  metadata: PostMetadata;
}

const CodeBlock = ({ language, value }: { language: string; value: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(value);
      } else {
        // Fallback for browsers that don't support clipboard API
        const textArea = document.createElement('textarea');
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="relative group not-prose">
      <button
        onClick={handleCopy}
        className="absolute right-2 top-2 z-10 p-2 rounded-lg bg-accent/10 hover:bg-accent/20 opacity-0 group-hover:opacity-100 transition-opacity"
        aria-label="Copy code"
      >
        {copied ? (
          <Check className="w-4 h-4 text-green-500" />
        ) : (
          <Copy className="w-4 h-4 text-accent" />
        )}
      </button>
      <SyntaxHighlighter
        language={language}
        style={{
          ...oneDark,
          'pre[class*="language-"]': {
            ...oneDark['pre[class*="language-"]'],
            background: '#1E1E1E',
          },
          'code[class*="language-"]': {
            ...oneDark['code[class*="language-"]'],
            background: 'transparent',
          },
          'span': {
            background: 'transparent !important'
          }
        }}
        customStyle={{
          margin: 0,
          borderRadius: '0.5rem',
          background: '#1E1E1E',
        }}
        wrapLongLines
        useInlineStyles={true}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
};

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!id) throw new Error('No post ID provided');
        
        const postFiles = import.meta.glob('../articles/*.md', { as: 'raw', eager: true });
        const posts = Object.keys(postFiles);
        
        const postPath = posts.find(path => {
          const filename = path.split('/').pop() || '';
          return filename === `${id}.md` || filename === id;
        });

        if (!postPath) {
          throw new Error('Post not found');
        }

        const content = postFiles[postPath];
        const { attributes, body } = frontMatter<PostMetadata>(content);
        
        // Handle old posts that might have tags but no category
        const category = attributes.category || (attributes.tags?.[0] || 'Uncategorized');
        
        setPost({
          content: body,
          metadata: {
            ...attributes,
            category: category
          }
        });
      } catch (error) {
        console.error('Error loading post:', error);
        setPost(null);
      }
    };

    fetchPost();
  }, [id]);

  if (!post) return null;

  return (
    <>
      <Helmet>
        <title>{post.metadata.title} | greycr0w</title>
        <meta name="description" content={post.metadata.excerpt || `${post.metadata.title} - Security research by greycr0w`} />
        <meta name="keywords" content={[post.metadata.category, ...(post.metadata.tags || [])].join(', ')} />
        
        {/* Open Graph */}
        <meta property="og:title" content={post.metadata.title} />
        <meta property="og:description" content={post.metadata.excerpt || `${post.metadata.title} - Security research by greycr0w`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://greycr0w.com/blog/${id}`} />
        
        {/* Article Specific */}
        <meta property="article:published_time" content={post.metadata.date} />
        <meta property="article:modified_time" content={post.metadata.lastModified || post.metadata.date} />
        <meta property="article:author" content={post.metadata.author} />
        <meta property="article:section" content={post.metadata.category} />
        {post.metadata.tags?.map(tag => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
        
        {/* If you have a canonical URL */}
        {post.metadata.canonicalUrl && (
          <link rel="canonical" href={post.metadata.canonicalUrl} />
        )}

        {/* JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.metadata.title,
            "description": post.metadata.excerpt,
            "author": {
              "@type": "Person",
              "name": post.metadata.author
            },
            "datePublished": post.metadata.date,
            "dateModified": post.metadata.lastModified || post.metadata.date,
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://greycr0w.com/blog/${id}`
            },
            "keywords": [post.metadata.category, ...(post.metadata.tags || [])].join(',')
          })}
        </script>
      </Helmet>
      <div className="min-h-screen bg-background text-foreground">
        <Header />
        
        <main className="container pt-24 pb-12">
          <article className="max-w-3xl mx-auto">
            <header className="mb-8">
              <h1 className="text-4xl font-medium mb-4">{post.metadata.title}</h1>
              <div className="flex flex-wrap gap-4 text-sm text-accent mb-4">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-2" />
                  {post.metadata.date}
                </div>
                <div className="flex items-center">
                  <User className="w-4 h-4 mr-2" />
                  {post.metadata.author}
                </div>
                <span className="px-2 py-0.5 text-xs rounded-full bg-accent/10 text-primary 
                  border border-accent/20"
                >
                  {post.metadata.category}
                </span>
              </div>
            </header>
            
            <div className="prose prose-invert max-w-none 
              prose-h1:text-red-500 
              prose-h2:text-red-400 
              prose-h3:text-red-300 
              prose-h4:text-red-200
              prose-h1:border-b 
              prose-h1:border-red-500/20 
              prose-h1:pb-2
              prose-h2:border-b 
              prose-h2:border-red-400/20 
              prose-h2:pb-2"
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    const value = String(children).replace(/\n$/, '');
                    
                    if (!inline && match) {
                      return (
                        <div className="my-6">
                          <CodeBlock
                            language={match[1]}
                            value={value}
                          />
                        </div>
                      );
                    }
                    
                    return (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }
                }}
              >
                {post.content}
              </ReactMarkdown>
            </div>
          </article>
        </main>
      </div>
    </>
  );
};

export default BlogPost;
