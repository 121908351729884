import Header from "../components/Header";
import { Helmet } from 'react-helmet-async';
import ModularTerminal from "../components/ModularTerminal";

const About = () => {
  const techStackCommands = {
    stack: `• React + TypeScript
• TailwindCSS
• Markdown Processing
• Interactive Terminal
• Syntax Highlighting`,
    help: 'Available commands:\n  stack - Show the tech stack\n  help - Show this help message\n  clear - Clear terminal output',
  };

  return (
    <>
      <Helmet>
        <title>about</title>
        <meta name="description" content="Security researcher focused on web security, vulnerability research, and penetration testing. Read about my background and research interests." />
        <meta name="keywords" content="security researcher, web security, penetration testing, vulnerability research, about" />
        <meta property="og:title" content="About greycr0w | Security Researcher" />
        <meta property="og:description" content="Security researcher focused on web security, vulnerability research, and penetration testing. Read about my background and research interests." />
        <meta property="og:type" content="profile" />
      </Helmet>
      <div className="min-h-screen bg-background">
        <Header />
        <main className="container pt-24 pb-12">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold mb-8 text-foreground">about</h1>
            
            <div className="prose prose-invert max-w-none">
              <p className=" mb-6 text-primary">
                Security engineer at Meta with a focus on application security and privacy. Previously worked at BNP Paribas and AXA. I specialize in penetration testing, threat modeling, and building security-first engineering cultures. Through this blog, I share practical insights from my experience in keeping large-scale systems secure without compromising on innovation speed.
              </p>
              
              <h2 className="text-2xl font-bold mt-12 mb-4 text-foreground">research focus</h2>
              <ul className="space-y-3 list-disc pl-6 text-primary">
                <li>Web Application Security</li>
                <li>Zero-Day Research</li>
              </ul>
              
              <h2 className="text-2xl font-bold mt-12 mb-4 text-foreground">this site</h2>
              <p className="mb-6 text-primary">
                This website is custom-built from scratch using React and TypeScript, with no templates or website builders. It features a minimalist design inspired by terminal interfaces, including a fully functional terminal emulator with Unix-like commands. Content is managed through markdown files for easy maintenance, and every component—from the article cards to the syntax highlighter—is custom-crafted for performance and accessibility.
              </p>
              <ModularTerminal 
                commands={techStackCommands}
                initialCommand="stack"
                className="mb-12"
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default About;
